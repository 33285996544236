<template>
  <div class="page">
    <!-- 在线学习列表 -->
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div class="online_learning_list">
        <div
          class="item"
          @click="toDetail(item, index)"
          v-for="(item, index) in study_list"
          :key="index"
        >
          <div class="img_box">
            <img class="bg" :src="item.topicPicture" alt="" />
            <div class="box">
              <div class="quantity">{{ item.readCount }}次播放</div>
              <div class="duration">
                <div class="imgs">
                  <img class="duration_icon" src="./img/good.png" alt="" />
                </div>
                <div class="infos">{{ item.praiseCount }}</div>
              </div>
            </div>
          </div>
          <div class="txt_box">{{ item.topicTitle }}</div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import {
  getInitPageNoUrl,
  getTopicListUrl,
  getCpbCourseListUrl,
} from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "onlineStudy",
  data() {
    return {
      fromFlag: "",
      study_list: [],
      total: 0,
      finishedText: "没有更多了",
      finished: false,
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
      status: "1",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    // this.getList()
    this.fromFlag = this.$route.query.fromFlag;
  },
  methods: {
    onLoad() {
      this.getList();
    },
    // 获取课程列表
    getList() {
      let params = {
        name: "",
        // communityId: this.communityId,
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        // tenantId: this.tenantId,
        status: this.status,
        topicType: "18",
      };
      this.$axios
        .get(`${getTopicListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.study_list.push(item);
            });
            this.study_list.forEach((ele) => {
              if (ele.topicPicture) {
                ele.topicPicture = this.$handleImg(333, 214, ele.topicPicture);
              }
            });
          } else {
          }
        });
    },
    toDetail(item) {
      let params = {
        id: item.id,
      };
      this.$router.push({
        name: "onLineStudyDetail",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #fff;

  .online_learning_list {
    // height: 100vh;
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    padding: 28px 32px 0;
    justify-content: space-between;

    .item {
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      box-sizing: border-box;
      margin-top: 16px;
      border-radius: 12px;
      overflow: hidden;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 0;
        margin-top: 0;
      }

      .txt_box {
        box-sizing: border-box;
        width: 333px;
        height: 102px;
        line-height: 45px;
        background-color: #fff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        padding: 12px;
        font-size: 28px;
        color: #323334;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .img_box {
        width: 333px;
        height: 214px;
        position: relative;

        .box {
          width: 100%;
          height: 50px;
          line-height: 50px;
          position: absolute;
          bottom: 0;
          left: 0;

          .duration {
            font-size: 22px;
            color: #fff;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);

            .imgs {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;

              .duration_icon {
                width: 24px;
                height: 24px;
              }
            }

            .infos {
              display: inline-block;
            }
          }

          .quantity {
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
          }
        }

        .bg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
